@mixin h1-style-desktop {
    font-size: var(--h1-font-size);
    font-weight: var(--h1-font-weight);
    font-style: var(--h1-font-style);
    color: var(--h1-color);
    line-height: var(--h1-line-height);
    text-transform: var(--h1-text-transform);
}

@mixin h1-style-mobile {
    font-size: var(--h1-font-size-mobile);
    font-weight: var(--h1-font-weight-mobile);
    font-style: var(--h1-font-style-mobile);
    color: var(--h1-color-mobile);
    line-height: var(--h1-line-height-mobile);
    text-transform: var(--h1-text-transform-mobile);
}

@mixin h1-style {
    @include desktop {
        @include h1-style-desktop;
    }

    @include mobile {
        @include h1-style-mobile;
    }
}

@mixin h2-style-desktop {
    font-size: var(--h2-font-size);
    font-weight: var(--h2-font-weight);
    font-style: var(--h2-font-style);
    color: var(--h2-color);
    line-height: var(--h2-line-height);
    text-transform: var(--h2-text-transform);
}

@mixin h2-style-mobile {
    font-size: var(--h2-font-size-mobile);
    font-weight: var(--h2-font-weight-mobile);
    font-style: var(--h2-font-style-mobile);
    color: var(--h2-color-mobile);
    line-height: var(--h2-line-height-mobile);
    text-transform: var(--h2-text-transform-mobile);
}

@mixin h2-style {
    @include desktop {
        @include h2-style-desktop;
    }

    @include mobile {
        @include h2-style-mobile;
    }
}

@mixin h3-style-desktop {
    font-size: var(--h3-font-size);
    font-weight: var(--h3-font-weight);
    font-style: var(--h3-font-style);
    color: var(--h3-color);
    line-height: var(--h3-line-height);
    text-transform: var(--h3-text-transform);
}

@mixin h3-style-mobile {
    font-size: var(--h3-font-size-mobile);
    font-weight: var(--h3-font-weight-mobile);
    font-style: var(--h3-font-style-mobile);
    color: var(--h3-color-mobile);
    line-height: var(--h3-line-height-mobile);
    text-transform: var(--h3-text-transform-mobile);
}

@mixin h3-style {
    @include desktop {
        @include h3-style-desktop;
    }

    @include mobile {
        @include h3-style-mobile;
    }
}

@mixin h4-style-desktop {
    font-size: var(--h4-font-size);
    font-weight: var(--h4-font-weight);
    font-style: var(--h4-font-style);
    color: var(--h4-color);
    line-height: var(--h4-line-height);
    text-transform: var(--h4-text-transform);
}

@mixin h4-style-mobile {
    font-size: var(--h4-font-size-mobile);
    font-weight: var(--h4-font-weight-mobile);
    font-style: var(--h4-font-style-mobile);
    color: var(--h4-color-mobile);
    line-height: var(--h4-line-height-mobile);
    text-transform: var(--h4-text-transform-mobile);
}

@mixin h4-style {
    @include desktop {
        @include h4-style-desktop;
    }

    @include mobile {
        @include h4-style-mobile;
    }
}

@mixin h5-style-desktop {
    font-size: var(--h5-font-size);
    font-weight: var(--h5-font-weight);
    font-style: var(--h5-font-style);
    color: var(--h5-color);
    line-height: var(--h5-line-height);
    text-transform: var(--h5-text-transform);
}

@mixin h5-style-mobile {
    font-size: var(--h5-font-size-mobile);
    font-weight: var(--h5-font-weight-mobile);
    font-style: var(--h5-font-style-mobile);
    color: var(--h5-color-mobile);
    line-height: var(--h5-line-height-mobile);
    text-transform: var(--h5-text-transform-mobile);
}

@mixin h5-style {
    @include desktop {
        @include h5-style-desktop;
    }

    @include mobile {
        @include h5-style-mobile;
    }
}

@mixin h6-style-desktop {
    font-size: var(--h6-font-size);
    font-weight: var(--h6-font-weight);
    font-style: var(--h6-font-style);
    color: var(--h6-color);
    line-height: var(--h6-line-height);
    text-transform: var(--h6-text-transform);
}

@mixin h6-style-mobile {
    font-size: var(--h6-font-size-mobile);
    font-weight: var(--h6-font-weight-mobile);
    font-style: var(--h6-font-style-mobile);
    color: var(--h6-color-mobile);
    line-height: var(--h6-line-height-mobile);
    text-transform: var(--h6-text-transform-mobile);
}

@mixin h6-style {
    @include desktop {
        @include h6-style-desktop;
    }

    @include mobile {
        @include h6-style-mobile;
    }
}

@mixin paragraph-style-desktop {
    color: var(--paragraph-color);
    font-size: var(--paragraph-font-size);
    font-style: var(--paragraph-font-style);
    font-weight: var(--paragraph-font-weight);
    line-height: var(--paragraph-line-height);
    text-transform: var(--paragraph-text-transform);
}

@mixin paragraph-style-mobile {
    font-size: var(--paragraph-font-size-mobile);
    font-weight: var(--paragraph-font-weight-mobile);
    font-style: var(--paragraph-font-style-mobile);
    color: var(--paragraph-color-mobile);
    line-height: var(--paragraph-line-height-mobile);
    text-transform: var(--paragraph-text-transform-mobile);
}

@mixin paragraph-style {
    @include desktop {
        @include paragraph-style-desktop;
    }

    @include mobile {
        @include paragraph-style-mobile;
    }
}