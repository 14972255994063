/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/util';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

@import '../../style/abstract/variables/font_awesome';
@import '../../style/abstract/variables/icon_font';
@import '../../style/abstract/button_extend';
@import '../../style/abstract/icon_font';
@import '../../style/abstract/typography';
@import '../../style/abstract/input';
@import '../../style/abstract/block';
@import '../../style/abstract/volume_pricing_guide';

.FirebaseLogin {
    &-Wrapper {
        .firebaseui-container {
            max-width: unset;
        }

        .firebaseui-card-content {
            padding-inline: 0;
        }

        .firebaseui-idp-list {
            margin-block: 0;

            > .firebaseui-list-item {
                margin-block-end: 10px;

                &::before {
                    display: none;
                }

                &:last-child {
                    margin-block-end: 0;
                }
            }
        }

        .firebaseui-idp-button {
            max-width: unset;
        }

        .firebaseui-idp-text {
            margin-block-start: 2px;
            line-height: normal;
            font-family: var(--default-font-family);
            font-weight: 500;
            font-size: 14px;
        }

        button {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 48px;
            @include box-shadow(none);
            @include border-radius(var(--button-border-radius));

            &:focus, &:active {
                @include box-shadow(none);
            }

            &[data-provider-id="google.com"] {
                border: 1px solid var(--color-light-gray);

                .firebaseui-idp-text {
                    color: var(--primary-text-color);
                }
            }
        }
    }
}
