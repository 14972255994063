/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/util';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

@import '../../style/abstract/variables/font_awesome';
@import '../../style/abstract/variables/icon_font';
@import '../../style/abstract/button_extend';
@import '../../style/abstract/icon_font';
@import '../../style/abstract/typography';
@import '../../style/abstract/input';
@import '../../style/abstract/block';
@import '../../style/abstract/volume_pricing_guide';

.SearchMenu {
    &-Wrapper {
        .bm-menu {
            overflow: visible;
            padding: 10px;
        }

        .bm-item-list {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0;

            .SearchField {
                margin: 0;
                height: 100%;

                &-Wrapper {
                    height: 100%;
                }

                &-SearchInnerWrapper {
                    height: 100%;
                }

                &-MenuWrapper {
                    width: 100%;
                    height: 100%;
                    margin: 0 15px;
                }

                &-Input {
                    padding: 0;
                    height: 100%;
                    text-indent: 10px;
                }

                &-SearchIcon {
                    display: none;
                }
            }

            .SearchIcon {
                height: 40px;
                width: 40px;
            }
        }
    }

    &-CloseMenu {
        color: var(--color-dark-gray);
        font-size: 16px;
        font-weight: 500;
    }
}
