.PageType {
    &-Cart, &-ProductListingPage, &-Checkout {
        background-color: var(--color-gray);
    }

    &-Cart {
        .Breadcrumbs {
            display: none;
        }
    }
}
