/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/util';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

@import '../../style/abstract/variables/font_awesome';
@import '../../style/abstract/variables/icon_font';
@import '../../style/abstract/button_extend';
@import '../../style/abstract/icon_font';
@import '../../style/abstract/typography';
@import '../../style/abstract/input';
@import '../../style/abstract/block';
@import '../../style/abstract/volume_pricing_guide';

:root {
    @include desktop {
        --header-top-menu-height: 0;
        --header-height: 136px;
        --header-logo-height: 100px;
        --header-logo-width: 149px;
    }

    @include mobile {
        --header-height: 61px;
    }
}

.Header {
    position: initial;

    &-Wrapper {
        width: 100vw;
        margin-block-end: 0;
        flex-direction: column;

        &.Sticky {
            position: fixed;
            z-index: 100;
            inset-block-start: 0;
        }

        .page-top-message-bar {
            color: var(--color-white);
            text-align: center;
            font-size: 14px;
            line-height: 20px;
            padding: 16px 18px;

            * {
                color: var(--color-white);
            }

            a {
                font-size: inherit;
                font-weight: 700;
            }

            &::before {
                content: "";
                display: block;
                position: absolute;
                inset-block-start: 0;
                inset-inline-start: 50%;
                width: 100vw;
                height: 100%;
                z-index: -1;
                transform: translateX(-50%);
                background-color: var(--color-dark-blue);
            }
        }
    }

    &-Nav {
        display: grid;
    }

    &_isCheckout {
        .Header {
            &-Nav {
                display: flex;
            }

            &-SecureCheckout {
                display: flex;
                align-items: center;
                justify-content: center;

                h1 {
                    display: flex;
                    align-items: center;

                    svg {
                        font-size: .8em;
                        margin-inline-start: 15px;
                    }
                }
            }
        }
    }
}

@include desktop {
    .Header {
        height: 137px;

        &-Nav {
            grid-template-columns: repeat(36, 1fr);
            column-gap: 1.5rem;
            height: auto;
            padding: 18px;
        }

        &-LogoWrapper {
            position: relative;
            grid-column: span 5;
            grid-row: span 2;
            height: var(--header-logo-height);
            width: var(--header-logo-width);
        }

        &-SearchFieldAndIconsWrapper {
            grid-column: span 31;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &-IconsWrapper {
            justify-content: end;
        }

        &-MyAccountContainer {
            margin-inline-start: 0;
        }

        &-MyAccountWrapper {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            svg {
                height: 32px;
                width: 32px;
            }
        }

        &-MyAccountLabel, &-MinicartLabel {
            font-size: 14px;
            cursor: pointer;
            white-space: nowrap;
        }

        &-Button_type_minicart {
            height: 30px;
            width: auto;
        }

        &-MinicartButtonWrapper {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            svg {
                height: 30px;
                width: 30px;
            }
        }

        &-MinicartItemCount {
            inset-block-start: -2px;
            inset-inline-end: 30px;
        }

        &-MinicartLabel {
            margin-inline-start: 10px;
        }

        .SearchField {
            padding-inline-end: 0;

            &-MenuWrapper {
                width: 100%;
                padding-inline-end: 60px;
            }
        }

        .Menu-MenuWrapper {
            grid-column: span 31;
            margin-block-start: 0;
            margin-block-end: -10px;

            .Menu-MainCategories {
                margin-inline-start: 0;
                width: 100%;

                .Menu-ItemCaption_type_main {
                    margin-block-end: 0;
                }
            }

            .Menu-ItemList {
                &_type_main {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;

                    .Menu-Item {
                        @include desktop {
                            margin-inline-start: 0;
                            padding-inline-end: 30px;
                        }

                        &:last-child {
                            margin-block-end: 0;
                        }
                    }
                }
            }
        }

        &_isCheckout {
            .Header {
                &-Nav {
                    justify-content: flex-start;

                    &::after {
                        content: " ";
                        display: block;
                        width: 25%;
                    }
                }

                &-LogoWrapper {
                    width: 25%;

                    .Image-Image {
                        position: initial;
                        display: inline-block;
                        width: auto;
                    }
                }

                &-SecureCheckout {
                    width: 50%;

                    h1 {
                        @include h3-style-desktop;
                    }
                }
            }
        }
    }
}

@include mobile {
    .Header {
        height: 61px;

        &-Nav {
            grid-template-columns: repeat(12, 1fr);
            padding-block-start: 4px;
            padding-block-end: 2px;
        }

        &-LogoWrapper {
            height: 100%;
            max-height: 60px;
            grid-column: span 4;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-block-start: -1px;
            margin-block-end: 0;

            > .Logo {
                height: 52px;
            }
        }

        &-MinicartButtonWrapper, .SearchMenuButton-Button {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 28px;
            width: 28px;

            .CartIcon, .SearchIcon {
                height: 28px;
                width: 28px;
            }
        }

        &-MinicartButtonWrapper {
            margin-inline-end: 5px;
        }

        .SearchMenuButton-Button {
            margin-inline-end: 10px;
        }

        .MainMenuButton-Button {
            height: 28px;
            width: 28px;
            padding: 0;
            grid-column: span 4;
        }

        &-RightSideButtons {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            grid-column: span 4;
        }

        &_name_category {
            border-bottom: 1px solid var(--primary-divider-color);
        }

        &_isCheckout {
            border-bottom: none;
            padding: 0;

            .Header {
                &-Nav {
                    height: unset;
                    justify-content: center;
                    flex-wrap: wrap;
                    padding: 0;

                    > * {
                        width: 100%;
                    }
                }

                &-LogoWrapper, &-SecureCheckout {
                    padding-inline: 15px;
                }

                &-LogoWrapper {
                    margin-block-start: 0;
                    padding-block: 5px;
                }

                &-SecureCheckout {
                    width: 100%;
                    padding-block: 20px;
                    background-color: var(--color-gray);
                }
            }
        }

        &-Wrapper {
            .page-top-message-bar {
                color: var(--color-white);
                text-align: center;
                font-size: 14px;
                line-height: 20px;
                padding: 16px 18px;

                * {
                    color: var(--color-white);
                }

                &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    inset-block-start: 0;
                    inset-inline-start: 50%;
                    width: 100vw;
                    height: 100%;
                    z-index: -1;
                    transform: translateX(-50%);
                    background-color: var(--color-dark-blue);
                }
            }
        }
    }
}
