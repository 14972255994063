/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/util';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

@import '../../style/abstract/variables/font_awesome';
@import '../../style/abstract/variables/icon_font';
@import '../../style/abstract/button_extend';
@import '../../style/abstract/icon_font';
@import '../../style/abstract/typography';
@import '../../style/abstract/input';
@import '../../style/abstract/block';
@import '../../style/abstract/volume_pricing_guide';

@include desktop {
    .Menu {
        &-ItemCaption {
            color: var(--primary-text-color);

            &_isTertiary {
                margin-inline-start: 20px;
            }
        }

        &-SubCategoriesWrapper {
            &_isVisible {
                position: fixed;
                inset-inline-start: 0;
                width: 100vw;
                height: max-content;
                background-color: var(--color-white);
                border-bottom: 1px solid var(--primary-divider-color);
                overflow: hidden;
                padding-block: 30px;

                @include box-shadow(0 5px 10px rgba(0, 0, 0, .1));
            }

            &Inner {
                max-width: var(--content-wrapper-width);
                height: max-content;
                margin: 0 auto;
                padding: 0;
                overflow-y: auto;

                > .Menu-SubCategories {
                    height: 100%;
                    padding-inline: 16px;

                    > .Menu-SubMenu {
                        height: 100%;

                        > .Menu-ItemList {
                            //TODO
                        }
                    }
                }
            }

            .Menu-SubMenu {
                margin-block-end: 0;
            }
        }

        &-MenuWrapper {
            a, .Menu-Link {
                color: var(--primary-text-color);
            }

            .Menu-MainCategories {
                .Menu-ItemCaption {
                    text-transform: none;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 20px;
                    white-space: nowrap;
                    color: var(--primary-text-color);
                }
            }

            .Menu-ItemCaption {
                &_type_subcategory {
                    font-weight: 400;
                }
            }

            .Menu-ItemList {
                &_type_subcategory {
                    column-gap: 5%;

                    .Menu-ItemListChunk {
                        grid-column: span 3;

                        .Menu-SubItemWrapper {
                            .Menu-ItemCaption {
                                font-size: 15px;
                            }
                        }

                        .Image {
                            height: 100%;

                            &-Image {
                                inset-block-start: 0;
                                inset-inline-end: 0;
                                object-fit: cover;
                            }
                        }

                        &_hasThirdLevel {
                            .Menu-SubLevelDesktop {
                                .Menu-ItemCaption {
                                    margin-block-start: 0;
                                    padding-block-end: 8px;
                                }
                            }

                            > .Menu-SubItemWrapper:first-child {
                                > .Menu-Link {
                                    .Menu-ItemCaption {
                                        display: block;
                                        border-bottom: 1px solid;
                                        border-color: var(--primary-text-color);
                                        text-transform: uppercase;
                                        font-weight: 500;
                                        margin-block-end: 10px;
                                        padding-block-end: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            /**
             * do not remove this. It creates a hover area between the menu links and the menu itself.
             * without it, the menu would close before the mouse can reach it
             */

            &::before {
                content: "";
                width: calc(100% + 50px);
                height: 60px;
                display: block;
                position: absolute;
                inset-block-start: -10px;
                inset-inline-start: -20px;
                padding-inline-start: 50px;
                padding-inline-end: 50px;
                background-color: transparent;
                z-index: 0;
                overflow: hidden;
            }
        }
    }
}

@include mobile {
    .Menu {
        &-MenuWrapper {
            .Menu {
                &-MainCategories {
                    padding-inline-start: 0;
                    padding-block-end: 60px;
                }

                &-Item {
                    margin-inline-end: 0;
                    margin-block-end: 0;
                    border-bottom: 1px solid var(--expandable-content-divider-color);

                    &:last-child {
                        border-bottom: none;
                    }

                    .ChevronIcon, [class*='fa-chevron'] {
                        width: var(--expandable-content-chevron-icon-width-mobile);
                        height: var(--expandable-content-chevron-icon-height-mobile);
                        margin: var(--expandable-content-chevron-icon-margin-mobile);
                    }
                }

                &-ItemCaption {
                    margin: var(--expandable-content-menu-item-caption-margin-mobile);
                    padding: var(--expandable-content-menu-item-caption-padding-mobile);
                    text-transform: var(--expandable-content-menu-item-caption-text-transform-mobile);
                    font-weight: var(--expandable-content-menu-item-caption-font-weight-mobile);
                    font-size: var(--expandable-content-menu-item-caption-font-size-mobile);
                    line-height: var(--expandable-content-menu-item-caption-line-height-mobile);
                    white-space: var(--expandable-content-menu-item-caption-white-space-mobile);
                    color: var(--expandable-content-color);

                    &_isExpanded {
                        padding-block-end: 0;
                    }

                    &_type_main + .Menu-SubMenu {
                        padding-block: 10px;
                    }

                    &_type_subcategory {
                        padding: 0;
                        font-weight: 400;
                    }
                }

                &-Link {
                    display: block;
                    margin: 0;
                    padding: 0;
                    font-size: 14px;
                }

                &-SubMenu {
                    background: transparent;
                    padding-inline-end: 0;

                    .Menu {
                        &-ItemCaption {
                            font-size: 14px;
                            display: block;
                            margin: 0;
                            padding: 5px 0;
                        }
                    }

                    .Menu-ItemList_type_subcategory {
                        padding: 0;
                    }
                }
            }
        }
    }
}
