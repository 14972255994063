.slick-list, .slick-slider, .slick-track {
    position: relative;
    display: block
}

.slick-loading .slick-slide, .slick-loading .slick-track {
    visibility: hidden
}

.slick-slider {
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent
}

.slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0
}

.slick-list:focus {
    outline: 0
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand
}

.slick-slider .slick-list, .slick-slider .slick-track {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    inset-block-start: 0;
    inset-inline-start: 0
}

.slick-track::after, .slick-track::before {
    display: table;
    content: ''
}

.slick-track::after {
    clear: both
}

.slick-slide {
    display: none;
    float: inline-start;
    height: 100%;
    min-height: 1px
}

[dir=rtl] .slick-slide {
    float: inline-end;
}

.slick-slide img {
    display: block
}

.slick-slide.slick-loading img {
    display: none
}

.slick-slide.dragging img {
    pointer-events: none
}

.slick-initialized .slick-slide {
    display: block
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent
}

.slick-arrow.slick-hidden {
    display: none
}

.slick-dots li button::before, .slick-next::before, .slick-prev::before {
    //font-family: slick;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.slick-loading .slick-list {
    //background: url(ajax-loader.gif) center center no-repeat #fff
}

.slick-next, .slick-prev {
    position: absolute;
    display: block;
    padding: 0;
    font-size: 0;
    line-height: 0;
    inset-block-start: 50%;
    width: 20px;
    height: 20px;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: 0;
    background: 0 0
}

.slick-next:focus, .slick-next:hover, .slick-prev:focus, .slick-prev:hover {
    color: transparent;
    outline: 0;
    background: 0 0
}

.slick-next:focus::before, .slick-next:hover::before, .slick-prev:focus::before, .slick-prev:hover::before {
    opacity: 1
}

.slick-next.slick-disabled::before, .slick-prev.slick-disabled::before {
    opacity: .25
}

.slick-next::before, .slick-prev::before {
    color: var(--color-dark-gray);
    font-family: var(--icon-font-family);
    font-weight: 600;
    font-size: 27px;
}

.slick-prev {
    inset-inline-start: -25px
}

[dir=rtl] .slick-prev {
    inset-inline-end: -25px;
    inset-inline-start: auto;
    width: 25px;
    height: 25px;
}

.slick-prev::before {
    content: var(--icon-chevron-left-content);
}

.slick-next {
    inset-inline-end: -25px
}

[dir=rtl] .slick-next {
    inset-inline-end: auto;
    inset-inline-start: -25px
}

.slick-next::before {
    content: var(--icon-chevron-right-content);
}

.slick-dotted.slick-slider {
    margin-block-end: 30px
}

$slick-dot-size: 8px;

.slick-dots {
    width: 100%;
    margin: 15px 0;
    list-style: none;
    text-align: center;
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    li {
        position: relative;
        display: block;
        width: 16px;
        height: 16px;
        margin: 0;
        padding: 0;
        cursor: pointer;

        &::before {
            display: none;
        }

        &.slick-active {
            button::before {
                opacity: 1;
                color: var(--color-medium-gray);
            }
        }

        button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 16px;
            height: 16px;
            margin: 0;
            padding: 0;
            font-size: 0;
            line-height: 0;
            cursor: pointer;
            color: transparent;
            border: 0;
            outline: 0;
            background: 0 0;

            &::before {
                color: var(--color-lighter-gray);
                font-family: var(--icon-font-family);
                font-weight: 600;
                content: var(--icon-circle-content);
                font-size: $slick-dot-size;
                line-height: $slick-dot-size;
                position: absolute;
                inset-block-start: 0;
                inset-inline-start: 0;
                width: 16px;
                height: 16px;
                text-align: center;
                opacity: 1;
            }

            &:focus, &:hover {
                outline: 0;

                &::before {
                    opacity: 1;
                }
            }
        }
    }
}