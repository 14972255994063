@mixin input-style() {
    height: var(--input-height);
    border: 1px solid var(--input-border-color);
    background-color: var(--input-background-color);
    color: var(--input-color);
    padding: var(--input-padding);
    caret-color: var(--input-color);
    font-size: var(--input-font-size);

    @include border-radius(var(--input-border-radius));

    &:focus {
        border: 1px solid var(--input-border-color-focus);
    }
}

@mixin select-style() {
    @include input-style;

    padding: 15px 40px 14px 20px;
    appearance: none;

    & + .ChevronIcon {
        position: absolute;
        height: var(--select-chevron-icon-height);
        width: var(--select-chevron-icon-width);
        inset: unset 12px unset unset;
        pointer-events: none;
        color: var(--input-color);
    }
}

@mixin textarea-style() {
    @include input-style;

    appearance: none;
    height: 100px;
}

@mixin field-type-number {
    display: grid;

    .FieldNumber {
        &-InputWrapper {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            input {
                height: 48px;
                min-width: 70px;
                margin: 0 7px;
                padding: 0;
                font-size: 26px;
                font-weight: 500;
                color: var(--primary-text-color);
                background-color: var(--color-gray);
                pointer-events: initial;
                border: none;

                &:focus {
                    border: none;
                }

                &[disabled] {
                    color: var(--color-lighter-gray);
                }
            }
        }

        &-Label {
            display: block;
            width: 100%;
            text-align: center;
            font-size: 12px;
            font-weight: 500;
        }

        &-Button {
            @include button;
            @include button-hollow;
            width: 48px;
            height: 48px;
            margin: 0;
            padding: 0;

            &.AddButton {
                @include button-hollow-orange;
            }

            &.MinusButton {
                @include button-hollow-medium-gray;
            }

            &:hover, &:focus {
                width: 48px;
                height: 48px;
                padding: 0 !important;
            }

            [class*="Icon"] {
                width: 24px;
            }
        }
    }
}

@mixin field-type-number-small {
    .FieldNumber {
        &-InputWrapper {
            input {
                width: 45px;
                height: 38px;
                min-width: unset;
                margin: 0 5px;
                font-size: 22px;
            }
        }

        &-Button {
            width: 38px;
            height: 38px;

            &:hover, &:focus {
                width: 38px;
                height: 38px;
            }
        }

        &-Label {
            line-height: 16px;
        }
    }
}