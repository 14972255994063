.UrlKey {
    &-NonProfitsAndReligiousOrganizations,
    &-ClassroomSuppliesAndEducationalTools,
    &-CorporateGiftingAndPhilanthropy {
        [dir="ltr"] & {

            /**
             * defining these colors here bc they are only a part of the audience page design
             * and not part of the website's actual color pallet
             */
            $value-box-bg-color: #CED6EF;
            $value-box-border-color: darkgray;
            $value-box-text-color: #627394;
            $testimonial-bg-color: var(--color-dark-blue);

            .Breadcrumbs {
                display: none;
            }

            .CmsPage {
                &-Wrapper {
                    margin-inline: auto;
                    padding-inline: 0;

                    @include desktop {
                        margin-inline: auto;
                        padding-inline: 0;
                    }
                }

                &-Content {
                    > .section {
                        @include desktop {
                            max-width: var(--content-wrapper-width);
                            margin-inline: 18px;
                            padding-inline: 0;
                        }
                    }
                }
            }

            .section {
                a, a span {
                    @include paragraph-style;
                    color: var(--link-hover) !important;
                }
            }

            .top-section {
                @include background-full-width(var(--color-gray));

                @include desktop {
                    display: grid;
                    grid-gap: 0;
                    grid-template-columns: repeat(24, 1fr);
                }

                @include mobile {
                    display: flex;
                    flex-direction: column;
                    gap: 0;

                    > * {
                        margin-block: 0;
                    }
                }

                .left {
                    display: flex;
                    flex-direction: column;
                    row-gap: 3rem;
                    padding: 4rem;

                    @include desktop {
                        grid-column: span 11;
                        justify-content: center;
                    }

                    @include mobile {
                        padding-inline: 15px;
                    }

                    > h1 {
                        margin-block-end: 0;

                        @include desktop {
                            font-size: 32px;
                            line-height: 38px;
                        }

                        @include mobile {
                            font-size: 28px;
                            line-height: 35px;
                        }
                    }

                    ul {
                        @include paragraph-style;

                        > li {
                            &:last-child {
                                margin-block-end: 0;
                            }

                            &::before {
                                margin: unset;
                                padding: 0 1rem 0 0;
                                inset: unset;
                                font-family: var(--icon-font-family);
                                font-weight: 600;
                                content: $icon-arrow-right;
                            }
                        }
                    }
                }

                .right {
                    @include desktop {
                        grid-column: span 13;
                    }

                    img {
                        object-fit: cover;
                    }
                }
            }

            .value-props-section {
                display: flex;
                flex-direction: column;
                row-gap: 2rem;
                padding: 5rem 2rem 7rem;

                @include mobile {
                    padding-inline: 15px;
                }

                > * {
                    display: block;
                    max-width: 1000px;
                    margin-inline: auto;
                }

                h2 {
                    margin-block: 0;
                    text-align: center;

                    .one {
                        @include h3-style;
                    }

                    .two {
                        @include h2-style;
                    }
                }

                .content {
                    margin-block: 0;
                    padding: 0;
                    @include paragraph-style;

                    > *:last-child {
                        margin-block-end: 0;
                    }
                }

                .value-boxes {
                    display: flex;
                    justify-content: center;
                    padding: 0;
                    align-items: flex-start;
                    flex-wrap: wrap;
                    gap: 2rem;

                    .value-box {
                        background-color: $value-box-bg-color;
                        padding: 2rem;
                        border: 3px solid $value-box-border-color;

                        @media screen and (min-width: 625px) {
                            width: 275px;
                            height: 220px;
                        }

                        @media screen and (max-width: 624px) {
                            width: 80%;
                        }

                        * {
                            text-align: center;
                        }

                        .box-body {
                            color: $value-box-text-color;
                            text-align: center;
                        }

                        .box-header {
                            margin-block: 0 2rem;
                            text-align: center;
                            @include paragraph-style;
                            color: $value-box-text-color !important;
                            font-weight: 600 !important;
                        }

                        > p:last-of-type {
                            margin-block-end: 0;
                        }
                    }
                }
            }

            .shop-by-department-section {
                @include shop-by-department-section;
                margin: 0;
                padding-inline: 2rem;
            }

            .stars {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .testimonial {
                margin: 0 auto;
                background: $testimonial-bg-color;
                padding: 2rem;
                border-radius: 25px;
                max-width: 75%;

                > *:last-child {
                    margin-block-end: 0;
                }

                .stars {
                    margin-block-end: 1.5rem;
                }

                .stars, .stars * {
                    color: var(--color-white);
                    font-size: 24px;
                }

                .content {
                    color: var(--color-white);
                    text-align: center;
                    font-style: italic;

                }

                &-section {
                    padding-block: 8rem;
                }
            }

            .customer-logo-section {
                @include background-full-width(var(--color-gray));
                padding: 8rem 0;

                @include mobile {
                    padding-inline: 15px;
                }

                h2 {
                    @include h2-style;
                    margin-block: 0;
                    text-align: center;
                }

                .logos {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-wrap: wrap;
                    margin-block: 3rem;

                    @include desktop {
                        gap: 4rem;
                    }

                    @include mobile {
                        gap: 2rem;
                    }

                    > img {
                        @include border-radius(50%);

                        @include desktop {
                            max-height: 175px;
                            max-width: 175px;
                        }

                        @include mobile {
                            max-height: 100px;
                            max-width: 100px;
                        }
                    }
                }

                .content {
                    margin-block-end: 0;
                    @include paragraph-style;

                    > *:last-child {
                        margin-block-end: 0;
                    }
                }
            }

            .why-section {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;

                @include desktop {
                    padding: 8rem 0;
                }

                @include mobile {
                    padding: 0 0 8rem;
                    flex-direction: column;
                }

                h2 {
                    @include desktop {
                        margin-block-start: 0;
                    }

                    @include mobile {
                        margin-block-start: 4rem;
                    }
                }

                > div {
                    @include desktop {
                        width: calc(50% - 4rem);
                    }

                    @include mobile {
                        width: 100%;
                    }
                }

                .left {
                    .testimonial {
                        max-width: unset;
                        margin-block-start: 2rem;

                        @include mobile {
                            margin-inline: 15px;
                        }
                    }
                }

                .right {
                    @include mobile {
                        padding-inline: 15px;
                    }
                }
            }

            .why-eros-section {
                @include background-full-width(var(--color-gray));
                padding: 8rem 0;

                @include mobile {
                    padding-inline: 15px;
                }

                h2 {
                    margin-block-start: 0;
                    text-align: center;
                }

                .body {
                    @include paragraph-style;

                    .heading {
                        font-weight: 700;
                    }
                }

                .logos {
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    flex-wrap: wrap;
                    margin-block: 4rem 0;

                    @include mobile {
                        gap: 4rem;
                    }

                    img {
                        width: auto;

                        @include desktop {
                            max-height: 125px;
                        }

                        @include mobile {
                            max-height: 75px;
                        }
                    }
                }
            }

            .helping-section, .product-categories-section, .faq-section {
                padding: 8rem 0;

                @include mobile {
                    padding-inline: 15px;
                }

                h2 {
                    margin-block-start: 0;
                    text-align: center;
                }

                > *:last-child {
                    margin-block-end: 0;
                }
            }

            .product-categories-section {
                @include background-full-width(var(--color-gray));
            }

            .faq-section {
                .question {
                    font-size: 24px;
                    padding-block-start: 2rem;
                }
            }

            .shop-button {
                @include button;
                display: block;
                width: 100%;
                max-width: 250px;
                margin: 0 auto;
            }

            .bottom-links {
                .content-tiles-group {
                    &.two-across {
                        .widget-content-tile {
                            @include mobile {
                                width: 100vw;
                            }
                        }
                    }
                }
            }
        }
    }
}

