.ShopByDepartmentSection {
    &-TileWrapper {
        display: flex;
        align-items: flex-start;
        justify-content: space-around;
        flex-direction: row;
        flex-wrap: wrap;
        column-gap: 15px;
        row-gap: 40px;

        a.widget-content-tile {
            flex-basis: 10%;
            flex-grow: 1;
            max-width: 10%;

            > div.widget-content-tile {
                display: flex;
                flex-direction: column-reverse;
                align-items: center;
                justify-content: flex-start;
                column-gap: 10px;
            }
        }

        .image-container {
            width: 100%;

            .image {
                display: block;
                width: 100%;
                aspect-ratio: 1 / 1;
                border: 1px solid var(--color-dark-gray);
                @include border-radius(50%);
            }
        }

        .heading-container {
            width: 100%;
            margin-block-start: 1rem;

            > h1, h2, h3, h4, h5, h6, p, span {
                margin: 0;
                padding: 0;
                font-size: 15px;
                line-height: 18px;
                font-weight: 400;
                text-align: center;
            }
        }
    }
}

@include mobile {
    .ShopByDepartmentSection {
        &-TileWrapper {
            justify-content: space-between;

            a.widget-content-tile {
                flex-basis: 15%;
                max-width: 15%;
            }
        }
    }
}

@media screen and (max-width: 472px) {
    .ShopByDepartmentSection {
        &-TileWrapper {
            a.widget-content-tile {
                flex-basis: calc(50% - 10%);
                max-width: unset;
            }
        }
    }
}
