:root {
    --color-black: #000000;
    --color-white: #FFFFFF;
    --color-gray: #F3F6F7;
    --color-lighter-gray: #C4C4C4;
    --color-light-gray: #B4B4B4;
    --color-medium-gray: #666666;
    --color-dark-gray: #444444;
    --color-very-dark-gray: #0A0903;
    --color-orange: #F26323;
    --color-lighter-orange: #F4832F;
    --color-light-orange: lighten(var(--color-orange), 15%);
    --color-medium-orange: #CF6F28;
    --color-dark-orange: darken(var(--color-orange), 15%);
    --color-red: #FC0000;
    --color-medium-blue: #6DA0DE;
    --color-medium-blue-two: #0781D9;
    --color-dark-blue: #1037AD;

    --default-font-family: 'Rubik', sans-serif;

    --body-content-color: var(--color-dark-gray);
    --primary-text-color: var(--color-dark-gray);
    --primary-error-color: var(--color-red);
    --disabled-color: var(--color-lighter-gray);
    --selected-color: var(--color-gray);
    --sale-color: var(--color-red);

    --button-font-weight: 500;
    --button-disabled-color: var(--color-very-dark-gray);
    --button-disabled-border-color: var(--button-disabled-color);
    --button-disabled-background-color: var(--color-white);
    --button-disabled-opacity: .3;

    --footer-text-color: var(--color-white);
    --footer-button-hollow-color: var(--color-white);
    --footer-button-hollow-border-color: var(--color-white);
    --footer-column-heading-font-size: 19px;

    --icon-font-family: "Font Awesome 6 Free";

    --overlay-heading-font-size: 26px;
    --overlay-heading-font-weight: 500;
    --overlay-heading-font-style: normal;
    --overlay-heading-line-height: 32px;
    --overlay-heading-color: var(--color-dark-gray);
    --overlay-heading-height: 44px;
    --overlay-heading-margin: 0;

    --popup-heading-font-size-desktop: var(--overlay-heading-font-size);
    --popup-heading-font-weight-desktop: var(--overlay-heading-font-weight);
    --popup-heading-font-style-desktop: var(--overlay-heading-font-style);
    --popup-heading-line-height-desktop: var(--overlay-heading-line-height);
    --popup-heading-color-desktop: var(--overlay-heading-color);
    --popup-heading-height-desktop: var(--overlay-heading-height);
    --popup-heading-margin-desktop: var(--overlay-heading-margin);
    --popup-heading-font-size-mobile: var(--popup-heading-font-size-desktop);
    --popup-heading-font-weight-mobile: var(--popup-heading-font-weight-desktop);
    --popup-heading-font-style-mobile: var(--popup-heading-font-style-desktop);
    --popup-heading-line-height-mobile: var(--popup-heading-line-height-desktop);
    --popup-heading-color-mobile: var(--popup-heading-color-desktop);
    --popup-heading-height-mobile: var(--popup-heading-height-desktop);
    --popup-heading-margin-mobile: var(--popup-heading-margin-desktop);

    --close-icon-width: 35px;
    --close-icon-height: 35px;

    --required-text-color: var(--color-red);

    --expandable-content-menu-item-caption-font-size-mobile: 16px;
    --expandable-content-menu-item-caption-font-weight-mobile: 500;
    --expandable-content-menu-item-caption-line-height-mobile: 20px;
    --expandable-content-menu-item-caption-white-space-mobile: normal;
    --expandable-content-menu-item-caption-text-transform-mobile: none;
    --expandable-content-menu-item-caption-margin-mobile: 0;
    --expandable-content-menu-item-caption-padding-mobile: 23px 0;
    --expandable-content-chevron-icon-height-mobile: 18px;
    --expandable-content-chevron-icon-width-mobile: 18px;
    --expandable-content-chevron-icon-margin-mobile: 0;
    --expandable-content-color: var(--primary-text-color);
    --expandable-content-divider-color: var(--primary-divider-color);

    --product-actions-font-size-desktop: 14px;
    --product-actions-line-height-desktop: 19px;
    --product-actions-font-weight-desktop: 400;
    --product-actions-text-transform-desktop: unset;
    --product-actions-font-size-mobile: 13px;
    --product-actions-line-height-mobile: 16px;
    --product-actions-font-weight-mobile: 400;
    --product-actions-text-transform-mobile: unset;

    --emphasis-border: 2px solid var(--color-medium-blue-two);
}
