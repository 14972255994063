/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/util';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

@import '../../style/abstract/variables/font_awesome';
@import '../../style/abstract/variables/icon_font';
@import '../../style/abstract/button_extend';
@import '../../style/abstract/icon_font';
@import '../../style/abstract/typography';
@import '../../style/abstract/input';
@import '../../style/abstract/block';
@import '../../style/abstract/volume_pricing_guide';

.CustomAssortmentMenuButton {
    &-Button {
        display: flex;
        align-items: center;
        justify-content: center;

        .CartIcon {
            margin-inline-end: 0;
        }
    }

    &-ButtonLabel {
        display: flex !important;
        align-items: center;
        padding-inline-end: 10px;
        margin-inline-end: 10px;
        border-inline-end: 2px solid;
        height: 75%;
        text-align: center;
    }
}

@media screen and (max-width: 450px) {
    .CustomAssortmentMenuButton {
        &-ButtonLabel {
            width: 110px;
        }
    }
}