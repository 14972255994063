/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/util';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

@import '../../style/abstract/variables/font_awesome';
@import '../../style/abstract/variables/icon_font';
@import '../../style/abstract/button_extend';
@import '../../style/abstract/icon_font';
@import '../../style/abstract/typography';
@import '../../style/abstract/input';
@import '../../style/abstract/block';
@import '../../style/abstract/volume_pricing_guide';

.Notification {
    display: block;
    width: 100%;
    max-width: unset;
    min-height: unset;
    margin: 2px 0 0;
    padding: 20px 15px;
    inset-block-start: unset;
    text-align: center;
    justify-content: center;
    opacity: 0;

    @include border-radius(4px);
    @include box-shadow(0 5px 10px rgba(0, 0, 0, .1));

    &-Text {
        @include paragraph-style;

        font-weight: 500 !important;

    }
}
