@mixin lib-transform($prop: translateX(-50%) translateY(-50%)) {
    -webkit-transform: $prop;
    -moz-transform: $prop;
    -ms-transform: $prop;
    -o-transform: $prop;
    transform: $prop;
}

@mixin lib-transition($prop: all .25s ease-in-out) {
    transition: $prop;
    -webkit-transition: $prop;
    -moz-transition: $prop;
    -ms-transition: $prop;
}

@mixin background-full-width($background-color: inherit) {
    position: relative;

    &::before {
        display: block;
        content: "";
        position: absolute;
        inset-block-start: 0;
        inset-inline-start: 50%;
        margin: 0;
        width: 200vw;
        height: 100%;
        background: $background-color;
        background-color: $background-color;
        z-index: -1;

        @include lib-transform(translateX(-50%));
    }
}

@mixin border-radius($border-radius) {
    border-radius: $border-radius;
    -webkit-border-radius: $border-radius;
    -moz-border-radius: $border-radius;
}

@mixin box-shadow($box-shadow) {
    box-shadow: $box-shadow;
    -webkit-box-shadow: $box-shadow;
    -moz-box-shadow: $box-shadow;
    -o-box-shadow: $box-shadow;
}

@mixin full-body-mask($zIndex: 800) {
    display: block;
    position: fixed;
    inset: 50% 0 0 50%;
    transform: translate(-50%, -50%);
    background-color: var(--color-black);
    width: 1000vw;
    height: 1000vh;
    z-index: $zIndex;
    opacity: .25;
}

@mixin hide-scrollbar() {
    padding-inline-end: 24px;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}
