:root {
    --table-body-border: 1px solid #C8C8C8;
}

table {
    margin: 0;

    tbody, thead {
        tr {
            border-bottom: var(--table-body-border);

            &:last-child {
                border-bottom: var(--table-body-border);
            }

            th {
                background-color: transparent;
            }
        }
    }

    td, th {
        min-width: unset;
    }

    th {
        padding: 6px 0 16px;
    }

    td {
        padding: 16px 0;
    }

    thead {
        tr {
            th, th span {
                font-size: 10px;
                font-weight: 500;
                line-height: 14px;
                text-transform: uppercase;
                color: var(--color-medium-gray);
                letter-spacing: .1em;
            }
        }
    }

    tbody {
        tr {
            td, td span {
                font-size: 14px;
                line-height: 14px;
                letter-spacing: .05em;
                color: var(--color-black);
                vertical-align: center;
            }
        }
    }
}
