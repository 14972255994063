.show-more-less-clickable {
    [dir="ltr"] & {
        color: var(--link-color);
        text-decoration: none;
        font-weight: 500;

        &:hover {
            color: var(--link-hover);
            text-decoration: underline;
        }
    }
}