/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/util';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

@import '../../style/abstract/variables/font_awesome';
@import '../../style/abstract/variables/icon_font';
@import '../../style/abstract/button_extend';
@import '../../style/abstract/icon_font';
@import '../../style/abstract/typography';
@import '../../style/abstract/input';
@import '../../style/abstract/block';
@import '../../style/abstract/volume_pricing_guide';

$footer-vertical-spacing-size: 16px;

.Footer {
    @include mobile {
        margin-block-end: 0;
    }

    &-Content {
        color: var(--footer-text-color);

        h1, h2, h3, h4, h5, h6, a, p, span, div {
            color: var(--footer-text-color);
        }

        .Button {
            &_isHollow {
                @include button-hollow-white;
            }
        }
    }

    &-Columns {
        display: grid;
        gap: 20px;
        grid-template-columns: repeat(12, 1fr);
        padding-block-start: 76px;

        @include mobile {
            padding: 40px 40px 0;
        }

        .Footer-Column {
            margin-inline: auto;
            width: 100%;

            &:first-child {
                width: 296px;
                grid-column: span 3;
            }

            &:nth-child(2) {
                width: 276px;
                grid-column: span 4;

                > * {
                    max-width: 260px;
                    display: block;
                    margin-inline: auto;
                }
            }

            &:nth-child(3) {
                width: 214px;
                grid-column: span 3;

                > * {
                    max-width: 260px;
                    display: block;
                    margin-inline: auto;
                }

                .Footer-ColumnContent {
                    > * {
                        display: block;
                    }
                }

                &:nth-child(3) {
                    @include mobile {
                        a:last-child {
                            margin-block-end: 0;
                        }
                    }
                }
            }

            &:last-child {
                grid-column: span 2;
                width: 180px;
                margin-inline-end: 0;
            }

            @include mobile {
                &:not(:last-of-type) {
                    margin-block-end: 40px;
                }
            }

            &:not(:last-of-type) {
                max-width: unset;
                padding-inline-end: 0;
            }

            .social-media-icons {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                @include mobile {
                    justify-content: center;
                    margin-block-end: 0;

                    &:last-of-type {
                        margin-block-end: 0;
                    }
                }

                .social-media-icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 40px;
                    height: 40px;
                    margin-inline-end: 20px;
                    font-size: 14px;
                    border: 1px solid;

                    @include border-radius(3px);

                    @include mobile {
                        margin-block-end: 0;
                    }

                    &:last-child {
                        margin-inline-end: 0;
                    }
                }
            }
        }
    }

    &-ColumnTitle {
        font-style: var(--h3-font-style);
        font-weight: var(--h3-font-weight);
        font-size: var(--footer-column-heading-font-size);
        margin: 0 0 $footer-vertical-spacing-size;
    }

    &-ColumnItem {
        font-size: 19px;
        line-height: 23px;
        margin-block-end: $footer-vertical-spacing-size;
        display: inline-block;

        * {
            font-size: 19px;
            line-height: 23px;
            font-weight: 400;
        }

        a {
            display: inline-block;
        }

        & > a {
            margin-block-end: $footer-vertical-spacing-size;

            &:not(.social-media-icon):last-child {
                margin-block-end: 0;
            }
        }

        &:not(a):hover {
            text-decoration: none;
        }

        &:last-of-type {
            margin-block-end: $footer-vertical-spacing-size;
        }

        &.address {
            font-size: var(--paragraph-font-size);
            line-height: 22px;
        }
    }

    &-ColumnContent {
        .PaymentMethods-Wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-block-end: $footer-vertical-spacing-size;

            & > img {
                width: 53px;
                height: 30px;
                margin-inline-end: 5px;
            }
        }
    }

    &-CopyrightContentWrapper {
        padding: 20px 0 40px;
    }

    &-CopyrightContent {
        padding: 0;
        min-height: unset;
    }

    &-Copyright {
        color: var(--footer-text-color);
        opacity: .55;
    }
}

@media only screen and (max-width: 1111px) {
    .Footer {
        .Footer-Columns {
            .Footer-Column {
                &:last-child {
                    margin-inline: auto;

                    .Footer-ColumnTitle {
                        text-align: center;
                    }

                    .Footer-ColumnContent {
                        justify-content: center;
                        margin-inline: auto;

                        .PaymentMethods-Wrapper {
                            justify-content: center;

                            & > img {
                                &:last-child {
                                    margin-inline-end: 0;
                                }
                            }
                        }

                        p {
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 872px) and (max-width: 1111px) {
    .Footer {
        .Footer-Columns {
            .Footer-Column {
                grid-column: span 4;

                &:last-child {
                    grid-column: span 12;
                }
            }
        }
    }
}

@media only screen and (min-width: 645px) and (max-width: 871px) {
    .Footer {
        .Footer-Columns {
            .Footer-Column {
                grid-column: span 6;
            }
        }
    }
}

@media only screen and (min-width: 1px) and (max-width: 644px) {
    .Footer {
        .Footer-Columns {
            .Footer-Column {
                grid-column: span 12;
            }
        }

        .NewsletterSubscription {
            .FieldForm-Fieldset {
                .Field {
                    input {
                        text-align: center;
                    }
                }
            }
        }
    }
}
