/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/util';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

@import '../../style/abstract/variables/font_awesome';
@import '../../style/abstract/variables/icon_font';
@import '../../style/abstract/button_extend';
@import '../../style/abstract/icon_font';
@import '../../style/abstract/typography';
@import '../../style/abstract/input';
@import '../../style/abstract/block';
@import '../../style/abstract/volume_pricing_guide';

:root {
    --tooltip-border-color: var(--color-medium-gray);
    --tooltip-box-shadow: 3px 4px 4px rgba(0, 0, 0, 0.25);
}

.Overlay {
    .MyAccountOverlay-Heading {
        @include desktop {
            height: var(--overlay-heading-height);
            margin: var(--overlay-heading-margin);
            font-size: var(--overlay-heading-font-size);
            font-weight: var(--overlay-heading-font-weight);
            font-style: var(--overlay-heading-font-style);
            line-height: var(--overlay-heading-line-height);
            color: var(--overlay-heading-color);
        }
    }

    &.Tooltip {
        &_isVisible {
            padding: 25px;
            background: var(--color-white);
            border: 1px solid var(--tooltip-border-color);
            inset-block-start: 25px;

            @include box-shadow(var(--tooltip-box-shadow));

            &::before {
                content: '';
                position: absolute;
                inset-block-start: -9px;
                height: 15px;
                width: 15px;
                transform: rotate(45deg);
                background-color: var(--overlay-background);
                border-top: 1px solid var(--tooltip-border-color);
                border-left: 1px solid var(--tooltip-border-color);
            }

            &.Right {
                inset-inline-start: unset;
                inset-inline-end: -23px;

                &::before {
                    inset-inline-end: 20px;
                }
            }

            &.Left {
                inset-inline-start: -18px;
                inset-inline-end: unset;

                &::before {
                    inset-inline-start: 20px;
                }
            }

            p:last-child {
                margin-block-end: 0;
            }
        }
    }
}
