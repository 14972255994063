/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/util';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

@import '../../style/abstract/variables/font_awesome';
@import '../../style/abstract/variables/icon_font';
@import '../../style/abstract/button_extend';
@import '../../style/abstract/icon_font';
@import '../../style/abstract/typography';
@import '../../style/abstract/input';
@import '../../style/abstract/block';
@import '../../style/abstract/volume_pricing_guide';

.ShippingEstimatorDeliveryOptions {
    &-NoOptions {
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
    }

    .CheckoutDeliveryOption {
        padding: 0;

        &-Row {
            *, strong {
                @include paragraph-style;
            }
        }

        &_isActive {
            *, .CheckoutDeliveryOption-Row *, strong {
                font-weight: 500;
            }
        }
    }

    .CmsBlock {
        &-Wrapper_type_shipping_estimator_note {
            margin: 0 0 20px;

            > * {
                font-size: 15px;
                line-height: 20px;
                font-weight: 400;
            }

            p:last-child {
                margin-block-end: 0;
            }
        }
    }
}

@include desktop {
    .ShippingEstimatorDeliveryOptions {
        h3.ShippingEstimatorDeliveryOptions-Heading {
            margin-block-end: 8px;
        }

        > ul:first-of-type {
            margin-block-end: 10px;
        }

        &-NoOptions {
            margin-block-end: 20px;
        }

        .CheckoutDeliveryOption {
            &:hover, &:focus {
                *, .CheckoutDeliveryOption-Row * {
                    color: var(--link-hover);
                }
            }

            &_isActive {
                *, .CheckoutDeliveryOption-Row *, strong {
                    @include paragraph-style-desktop;
                    font-weight: 500;
                }
            }
        }
    }
}

@include mobile {
    .ShippingEstimatorDeliveryOptions {
        margin: 30px 0 0;

        &-Heading {
            display: none;
        }

        > ul:first-of-type {
            margin-block-end: 20px;
        }

        .CheckoutDeliveryOption {
            padding: 0 0 5px;
            border-block-start: none;

            .Field {
                margin-block-start: 0;
            }

            &_isActive {
                *, .CheckoutDeliveryOption-Row *, strong {
                    @include paragraph-style-mobile;
                    font-weight: 500;
                }
            }
        }
    }
}