$homepage-primary-slider-height: 440px;

.UrlKey-Home {
    .CmsPage-Wrapper {
        padding: 0 0 60px;
    }

    .primary-slider {
        @include background-full-width(var(--color-gray));

        .Slider {
            @include desktop {
                height: $homepage-primary-slider-height;
            }

            @include mobile {
                height: 375px;
            }

            .SliderWidget-Figure > .Image {
                @include desktop {
                    height: $homepage-primary-slider-height;
                }

                @include mobile {
                    height: 375px;
                }

                padding: 0;
            }

            &-Wrapper {
                background-color: transparent;
            }

            img {
                @include desktop {
                    max-height: $homepage-primary-slider-height;
                }

                @include mobile {
                    height: 375px;
                }
            }
        }
    }

    .slogan {
        width: max-content;
        margin: 0;
        padding: 0;
        letter-spacing: 0.05em;
        font-size: 19px;
        font-weight: 500;
        line-height: 67px;
        text-align: center;

        @include mobile {
            font-size: 19px;
            font-weight: 500;
            line-height: normal;
            margin: auto;
            padding: 17px 60px 0;
            width: 100%;
            max-width: 350px;
        }

        &-wrapper {
            font-size: 19px;
            font-weight: 400;
            line-height: 26px;

            @include desktop {
                display: flex;
                align-items: center;
                justify-content: center;

                .slogan {
                    margin-inline-end: 10px;
                }
            }
        }
    }

    .sub-slogan {
        width: max-content;
        font-size: 19px;
        font-weight: 400;
        line-height: 26px;

        @include mobile {
            display: block;
            margin-inline: auto;
            padding-block-end: 18px;
            font-size: 14px;
        }
    }

    .content-tiles-group {
        &.two-across {
            .widget-content-tile {
                @include mobile {
                    width: 100vw;
                }
            }
        }
    }

    .shop-by-department {
        margin: 60px 0;

        @include mobile {
            margin: 46px 0 33px;
        }

        @include shop-by-department-section;
    }

    .who-we-serve {
        margin-block-start: 60px;
        padding: 40px 0 60px;
        @include background-full-width(var(--color-gray));

        h2 {
            margin-block-start: 0;
            text-align: center;
            font-weight: 500;

            @include mobile {
                font-size: 32px;
                font-weight: 600;
                line-height: 50px;
            }
        }

        img {
            &:hover {
                filter: brightness(50%);
            }
        }

        .link-container {
            position: relative;

            p {
                position: absolute;
                inset-block-end: 8px;
                background: white;
                font-size: 24px;
                font-weight: 700;
                padding: 1rem 2rem;
            }
        }
    }
}
