/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/util';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

@import '../../style/abstract/variables/font_awesome';
@import '../../style/abstract/variables/icon_font';
@import '../../style/abstract/button_extend';
@import '../../style/abstract/icon_font';
@import '../../style/abstract/typography';
@import '../../style/abstract/input';
@import '../../style/abstract/block';
@import '../../style/abstract/volume_pricing_guide';

.MainMenu {
    &-AccountActionsWrapper {
        display: flex !important;
        height: 80px;
        width: 100%;
        margin-block-start: 20px;
        padding-inline-start: 15px;
        padding-inline-end: 15px;
        background-color: var(--color-gray);
        align-items: center;
        justify-content: space-between;
        font-size: var(--paragraph-font-size-mobile);

        .Header-MyAccountWrapper {
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }

        .UserIcon {
            width: 30px;
            height: 30px;
            margin-inline-end: 12px;
        }
    }

    &-SignOutButton {
        font-size: var(--paragraph-font-size-mobile);
    }
}
