:root {
    --input-height: 49px;
    --input-font-size: 17px;
    --input-border-color-focus: var(--color-dark-gray);
    --select-chevron-icon-height: 20px;
    --select-chevron-icon-width: 20px;
    --required-text-color: var(--color-red);
}

input, select {
    height: var(--input-height);
}

input, select, textarea {
    caret-color: var(--input-color);
    font-size: var(--input-font-size);
    border-color: var(--input-border-color);

    &:focus {
        border-color: var(--input-border-color-focus);
    }
}
