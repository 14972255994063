/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/util';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

@import '../../style/abstract/variables/font_awesome';
@import '../../style/abstract/variables/icon_font';
@import '../../style/abstract/button_extend';
@import '../../style/abstract/icon_font';
@import '../../style/abstract/typography';
@import '../../style/abstract/input';
@import '../../style/abstract/block';
@import '../../style/abstract/volume_pricing_guide';

.Breadcrumb {
    [dir="ltr"] & {
        margin: 0;

        @include desktop {
            font-size: 14px;
            line-height: 16px;
        }

        @include mobile {
            font-size: 12px;
            line-height: 16px;
        }

        &:last-child {
            margin-block-end: 0;

            .Breadcrumb-Link-Name {
                color: var(--color-dark-gray);
            }
        }

        &-Link, &-Link-Name {
            @include desktop {
                font-size: 14px;
                line-height: 16px;
            }

            @include mobile {
                font-size: 12px;
            }

            font-style: normal;
            font-weight: 400;
            color: var(--link-color);
        }
    }
}
