/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/util';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

@import '../../style/abstract/variables/font_awesome';
@import '../../style/abstract/variables/icon_font';
@import '../../style/abstract/button_extend';
@import '../../style/abstract/icon_font';
@import '../../style/abstract/typography';
@import '../../style/abstract/input';
@import '../../style/abstract/block';
@import '../../style/abstract/volume_pricing_guide';

$imageZoomPopupHeight: 85vh;
$imageZoomPopupWidth: 85vw;

.ImageZoomPopup {
    &_isVisible {
        max-width: $imageZoomPopupWidth;
        max-height: $imageZoomPopupHeight;
        z-index: 200;
    }

    &-PopupContent {
        &.Popup-Content {
            width: 100%;
            height: 100%;
        }
    }

    .ProductLabelGroup {
        display: none;
    }

    .ProductGallery {
        &-Additional_isImageZoomPopupActive {
            inset-block-start: 0;
            inset-block-end: 0;
        }

        .Slider-Wrapper {
            > div {
                height: $imageZoomPopupHeight !important;
                inset-block-start: 50%;
                transform: translateY(-50%);
                -ms-transform: translateY(-50%);

                > div > img {
                    height: $imageZoomPopupHeight !important;
                    max-height: $imageZoomPopupHeight !important;
                    object-fit: contain;
                }
            }
        }

        &-Slider {
            margin: 0 auto;
            max-width: 1000px;

            img {
                margin-inline-start: auto;
                margin-inline-end: auto;
                inset-inline-start: 0;
                inset-inline-end: 0;
            }
        }

        &-SliderImage {
            img {
                margin-inline-start: auto;
                margin-inline-end: auto;
                inset-inline-start: 0;
                inset-inline-end: 0;
            }
        }
    }

    .CarouselScroll {
        margin-block-start: 0;

        &-Content {
            flex-direction: column;
        }

        &-ContentWrapper {
            width: auto;
            flex: unset;
        }

        .CarouselScrollItem {
            margin-inline-start: 0;
            margin-block-end: 20px;
            flex: unset;

            &:last-child {
                margin-block-end: 0;
            }
        }
    }
}
