/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/util';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

@import '../../style/abstract/variables/font_awesome';
@import '../../style/abstract/variables/icon_font';
@import '../../style/abstract/button_extend';
@import '../../style/abstract/icon_font';
@import '../../style/abstract/typography';
@import '../../style/abstract/input';
@import '../../style/abstract/block';
@import '../../style/abstract/volume_pricing_guide';

.SearchField {
    @include desktop {
        width: 100%;
        grid-column: unset;
        margin-inline-start: 0;
        margin-inline-end: 0;
        padding-inline-end: 60px;
        max-width: unset;
    }

    &-SearchIcon {
        height: 48px;
        width: 48px;
        background-color: var(--secondary-base-color);
        display: flex;
        align-items: center;
        justify-content: center;
        inset-block-start: 0;
        inset-inline-end: 0;
        border-top-right-radius: var(--input-border-radius);
        -webkit-border-top-right-radius: var(--input-border-radius);
        -moz-border-radius-topright: var(--input-border-radius);
        border-bottom-right-radius: var(--input-border-radius);
        -webkit-border-bottom-right-radius: var(--input-border-radius);
        -moz-border-radius-bottomright: var(--input-border-radius);
    }

    &-CloseIcon {
        display: flex;
        align-items: center;
        justify-content: center;

        .CloseIcon {
            inset-block-start: 0;
            inset-block-end: 0;
        }
    }

    .SearchIcon {
        fill: var(--color-white);
    }
}
