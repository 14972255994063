:root {
    --imported_primary_base_color: var(--color-orange);
    --imported_primary_light_color: var(--color-light-orange);
    --imported_primary_dark_color: var(--color-dark-orange);
    --imported_secondary_light_color: var(--color-medium-blue-two);
    --imported_secondary_dark_color: darken(var(--color-dark-blue), 15%);
    --imported_secondary_base_color: var(--color-dark-blue);

    /** Paragraph base styles */
    --imported_paragraph_color: var(--primary-text-color);
    --imported_paragraph_font_size: 17px;
    --imported_paragraph_line_height: 26px;
    --imported_paragraph_font_weight: 400;
    --imported_paragraph_font_style: normal;
    --imported_paragraphMobile_color: var(--primary-text-color);
    --imported_paragraphMobile_font_size: 15px;
    --imported_paragraphMobile_line_height: 24px;
    --imported_paragraphMobile_font_weight: 400;
    --imported_paragraphMobile_font_style: normal;

    /** H1 base styles */
    --imported_h1_color: var(--color-dark-gray);
    --imported_h1_font_size: 64px;
    --imported_h1_line_height: 67px;
    --imported_h1_font_weight: 600;
    --imported_h1_font_style: normal;
    --imported_h1_text_transform: none;
    --imported_h1Mobile_color: var(--color-dark-gray);
    --imported_h1Mobile_font_size: 32px;
    --imported_h1Mobile_line_height: 32px;
    --imported_h1Mobile_font_weight: 500;
    --imported_h1Mobile_font_style: normal;
    --imported_h1Mobile_text_transform: none;

    /** H2 base styles */
    --imported_h2_color: var(--color-dark-gray);
    --imported_h2_font_size: 40px;
    --imported_h2_line_height: 50px;
    --imported_h2_font_weight: 500;
    --imported_h2_font_style: normal;
    --imported_h2_text_transform: none;
    --imported_h2Mobile_color: var(--color-dark-gray);
    --imported_h2Mobile_font_size: 26px;
    --imported_h2Mobile_line_height: 35px;
    --imported_h2Mobile_font_weight: 500;
    --imported_h2Mobile_font_style: normal;
    --imported_h2Mobile_text_transform: none;

    /** H3 base styles */
    --imported_h3_color: var(--color-dark-gray);
    --imported_h3_font_size: 32px;
    --imported_h3_line_height: 35px;
    --imported_h3_font_weight: 500;
    --imported_h3_font_style: normal;
    --imported_h3_text_transform: none;
    --imported_h3Mobile_color: var(--color-dark-gray);
    --imported_h3Mobile_font_size: 19px;
    --imported_h3Mobile_line_height: 23px;
    --imported_h3Mobile_font_weight: 500;
    --imported_h3Mobile_font_style: normal;
    --imported_h3Mobile_text_transform: none;

    /** Form inputs base styles */
    --imported_input_border_color: var(--color-light-gray);
    --imported_input_border_radius: 3px;
    --imported_input_color: var(--color-dark-gray);
    --imported_input_padding: 15px 15px 14px;

    /** Buttons base styles */
    --imported_buttons_background_color: var(--color-lighter-orange);
    --imported_buttons_border_color: var(--button-background);
    --imported_hoverButtons_background_color: var(--color-medium-orange);
    --imported_hoverButtons_border_color: var(--button-hover-background);
    --imported_hollowButtons_color: var(--color-dark-gray);
    --imported_hollowButtons_border_color: var(--color-dark-gray);
    --imported_hollowHoverButtons_color: var(--hollow-button-color);
    --imported_hollowHoverButtons_border_color: var(--hollow-button-border);

    /** Checkbox styles */
    --imported_checkboxes_border_color: var(--color-medium-blue-two);
    --imported_checkboxes_border_radius: 2px;
    --imported_checkboxes_width: 18px;
    --imported_checkboxes_height: 18px;

    /** Pagination styles */
    --imported_pagination_line_height: 25px;
    --imported_pagination_color: var(--color-dark-gray);

    /** Notification styles */
    --imported_notificationSuccess_color: #006400;
    --imported_notificationSuccess_background_color: #E5EFE5;
    --imported_notificationSuccess_height: unset;
    --imported_notificationInfo_color: #6F4400;
    --imported_notificationInfo_background_color: #FDF0D5;
    --imported_notificationInfo_height: unset;
    --imported_notificationError_color: #E02B27;
    --imported_notificationError_background_color: #FAE5E5;
    --imported_notificationError_height: unset;
}

html {
    overflow-x: hidden;
}

body {
    color: var(--primary-text-color);
    overflow-x: hidden;

    &.noscroll {
        overflow: hidden;
    }
}

span {
    @include paragraph-style;
}

li {
    * {
        color: var(--primary-text-color);
    }
}

#root {
    min-height: 100vh;

    > [class^="LocalizationWrapper-"] {
        min-height: 100vh;
    }
}
