@mixin shop-by-department-section {
    padding: 46px 0 63px;
    background-color: var(--color-medium-blue);

    @include background-full-width(var(--color-medium-blue));

    @include mobile {
        padding: 23px 30px 50px;
    }

    > h2 {
        font-weight: 500;
        text-align: center;
        color: var(--color-white);
        margin: 0 auto 43px;

        @include mobile {
            font-size: 32px;
            font-weight: 600;
            line-height: 50px;
            margin-block-end: 23px;
        }
    }

    .content-tiles-group {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        grid-column-gap: 27px;
        grid-row-gap: 50px;
        text-align: center;

        @include mobile {
            grid-template-columns: repeat(auto-fit, minmax(138px, 1fr));
            grid-row-gap: 37px;
        }

        .widget-content-tile.style-3 {
            grid-column: span 1;
            grid-gap: unset;
            width: 200px;
            max-width: 200px;
            margin: 0 auto;

            @include mobile {
                width: 138px;
                max-width: 138px;
            }

            .heading-container {
                width: 100%;
                height: 50px;
                padding-block-start: 20px;

                > h1, h2, h3, h4, h5, h6, p, span {
                    font-size: 19px;
                    line-height: 23px;
                    font-weight: 400;
                    color: var(--color-white);
                    text-align: center;

                    @include mobile {
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 18px;
                    }
                }
            }

            .image-container {
                width: 100%;
                height: 200px;
                margin: 0 auto;

                @include mobile {
                    height: 138px;
                }

                .image {
                    @include border-radius(50%);
                }
            }
        }
    }
}
