/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/util';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

@import '../../style/abstract/variables/font_awesome';
@import '../../style/abstract/variables/icon_font';
@import '../../style/abstract/button_extend';
@import '../../style/abstract/icon_font';
@import '../../style/abstract/typography';
@import '../../style/abstract/input';
@import '../../style/abstract/block';
@import '../../style/abstract/volume_pricing_guide';

.CustomAssortmentMenuOption {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-block: 15px;
    border-bottom: 1px solid var(--color-lighter-gray);

    &:last-child {
        border-width: 2px;
        border-color: var(--color-dark-gray);
    }

    &-Qty {
        margin: 0;
    }

    &-Label {
        margin-block-end: 0;
        font-size: 20px;
        font-weight: 400;
    }

    &.OutOfStock * {
        color: var(--disabled-color);
    }

    .FieldNumber {
        &-Label {
            display: none !important;
        }
    }

    .Field_type_number.Field_hasError::after {
        display: none;
    }

    .Field {
        &-ErrorMessages {
            text-align: center;
        }
    }

    &-RoundingMessageWrapper {
        margin-block-end: -10px;
        width: 100%;
        text-align: end;
    }

    &-RoundingMessage {
        font-size: 12px;
    }
}

@include mobile {
    [dir="ltr"] {
        .CustomAssortmentMenuOptions {
            .CustomAssortmentMenuOption {
                .Field {
                    &-Wrapper_type_number {
                        .FieldNumber {
                            &-Button {
                                width: 38px;
                                height: 38px;
                            }

                            &-Input {
                                max-width: 50px;
                                min-width: 50px;
                                height: 38px;
                                font-size: 22px;
                            }
                        }
                    }
                }
            }
        }
    }
}