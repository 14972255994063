.bm {
    &-burger {
        &-button {
            position: fixed;
            width: 36px;
            height: 30px;
            inset-inline-start: 36px;
            inset-block-start: 36px;
            background-color: var(--color-black);
        }

        &-bars {
            background: #373a47;

            &-hover {
                background: #a90000;
            }
        }
    }

    &-cross {
        background: #bdc3c7;

        &-button {
            height: 24px;
            width: 24px;
        }
    }

    &-header {
        height: 106px;
        padding: 20px;
        border-bottom: 1px solid var(--color-gray);
    }

    &-content {
        padding: 20px;
    }

    &-menu {
        height: 100%;
        overflow-y: scroll;
        padding: 20px;
        background: var(--color-white);

        &-wrap {
            inset-block-start: 0;
            position: fixed;
            height: 100vh;
            width: 100%;
        }
    }

    &-item {
        display: inline-block;

        &-list {
            padding: 0;
        }
    }

    &-overlay {
        background: rgba(0, 0, 0, 0.3);
        width: 100%;
        height: 100%;
        position: absolute;
        inset-block-start: 0;
        inset-inline-start: 0;
    }

    &-morph-shape {
        fill: #373a47;
    }
}