:root {
    /** link styles */
    --imported_link_color: var(--secondary-base-color);
    --imported_linkHover_color: var(--secondary-base-color);
    --imported_link_border_bottom: false;
    --imported_linkHover_border_bottom: false;
}

a {
    @include lib-transition;

    @include desktop {
        font-size: var(--paragraph-font-size);
        font-style: var(--paragraph-font-style);
        font-weight: var(--paragraph-font-weight);
        line-height: var(--paragraph-line-height);
        text-transform: var(--paragraph-text-transform);
    }

    @include mobile {
        font-size: var(--paragraph-font-size-mobile);
        font-weight: var(--paragraph-font-weight-mobile);
        font-style: var(--paragraph-font-style-mobile);
        line-height: var(--paragraph-line-height-mobile);
        text-transform: var(--paragraph-text-transform-mobile);
    }

    * {
        @include lib-transition;
    }
}

svg[class*="Icon"] {
    @include lib-transition;
}
