.content-tiles-group {
    &.two-across {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(458px, 1fr));
        grid-gap: 20px;

        @include mobile {
            grid-gap: 33px;
        }

        .widget-content-tile {
            grid-column: span 1;
        }
    }

    &.three-across {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(425px, 1fr));
        grid-gap: 22px;

        @include mobile {
            padding-inline-start: 15px;
            padding-inline-end: 15px;
        }

        .widget-content-tile {
            grid-column: span 1;
        }
    }
}

@media only screen and (min-width: 976px) and (max-width: 1378px) {
    .content-tiles-group {
        &.three-across {
            .widget-content-tile {
                grid-column: span 1;

                &:nth-child(3) {
                    grid-column: span 2;
                }
            }
        }
    }
}

@media only screen and (max-width: 975px) {
    .content-tiles-group {
        &.three-across {
            grid-template-columns: 1fr;
        }
    }
}

.widget-content-tile {
    &.style-1 {
        display: grid;
        grid-template-columns: 1fr;
        background-color: var(--color-gray);
        padding: 37px 0 29px;

        @include mobile {
            padding: 34px 0 28px;
        }

        > * {
            width: 100%;
            text-align: center;
        }

        .image-container {
            order: 1;
            max-height: 238px;

            @include mobile {
                max-height: 191px;
            }

            > img {
                object-fit: contain;
            }
        }

        .heading-container {
            order: 2;

            h1, h2, h3, h4, h5, h6, p, span {
                color: var(--color-dark-blue);
                font-size: 32px;
                font-weight: 500;
                line-height: 35px;
            }

            @include mobile {
                h1, h2, h3, h4, h5, h6, p, span {
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 20px;
                    margin: 20px auto 15px;
                }
            }
        }

        .button-container {
            order: 3;

            @include mobile {
                .Button {
                    width: auto;
                }
            }
        }
    }

    &.style-2 {
        .heading-container {
            max-width: 186px;
            position: absolute;
            inset-block-end: 68px;
            inset-inline-start: 20px;
            z-index: 2;

            h1, h2, h3, h4, h5, h6, p, span {
                margin: 0 0 10px;
                font-size: 28px;
                font-weight: 700;
                line-height: 29px;
                color: var(--color-white);
                text-align: start;
            }
        }

        .button-container {
            position: absolute;
            inset-block-end: 20px;
            inset-inline-start: 20px;
            z-index: 2;
        }

        .image-container {
            &::before {
                display: block;
                width: 50%;
                height: calc(100% - 4px);
                content: "";
                position: absolute;
                inset-block-start: 0;
                inset-inline-start: 0;
                background: linear-gradient(90deg, rgba(0, 0, 0, 0.6) 0.15%, rgba(254, 254, 254, 0) 99.88%);
                z-index: 1;
            }
        }
    }

    &.style-3 {
        display: grid;
        grid-template-columns: 1 1fr;
        grid-gap: 20px;

        .heading-container {
            order: 2;
            text-align: center;

            > * {
                font-size: var(--paragraph-font-size);
                margin: 0;
            }
        }

        .image-container {
            order: 1;
        }
    }
}
