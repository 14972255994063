.UrlKey-HomepageRedesign1 {
    .Breadcrumbs {
        display: none;
    }

    .SectionTitle {
        font-size: 35px;
        line-height: 42px;
        margin-block: 0 20px;
        text-align: start;
    }

    .TopSection {
        &-Title {
            max-width: 631px;
            margin-block: 0;
            font-size: 35px;
            line-height: 42px;
        }

        &-Text {
            font-size: 20px;
            line-height: 26px;
        }

        &-ContentWrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 15px;
        }

        &-ImageWrapper {
            aspect-ratio: 580 / 249;

            img {
                @include border-radius(8px);
            }
        }
    }

    .TiledSection {
        margin-block: 37px 25px;
    }

    .ShopByDepartmentSection {
        margin-block: 50px 25px;
    }

    .CustomerTypesSection {
        margin-block: 50px 25px;
    }

    .BestSellersSection {
        margin-block-start: 50px;
    }

    .CmsPage {
        background: transparent;
        background: -webkit-linear-gradient(180deg, #B7D1F0 0%, #B7D1F0 68%, #B7D1F000 100%);
        background: -moz-linear-gradient(180deg, #B7D1F0 0%, #B7D1F0 68%, #B7D1F000 100%);
        background: -o-linear-gradient(180deg, #B7D1F0 0%, #B7D1F0 68%, #B7D1F000 100%);
        background: linear-gradient(180deg, #B7D1F0 0%, #B7D1F0 68%, #B7D1F000 100%);
        background-size: 100% 652px;
        background-repeat: no-repeat;
        opacity: 1;

        &-Wrapper {
            margin-block-end: 50px !important;
            padding-block-end: 0;
        }
    }
}

@include desktop {
    .UrlKey-HomepageRedesign1 {
        .TopSection {
            max-width: 1358px;
            margin: 46px auto 0;
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 70px;

            &-ContentWrapper {
                width: 51%;
            }

            &-ImageWrapper {
                width: 44%;
            }
        }
    }
}

@include mobile {
    .UrlKey-HomepageRedesign1 {
        .TopSection {
            margin-block-start: 25px;

            &-ImageWrapper {
                display: none;
            }
        }

        .CmsPage {
            margin-block-end: 0;
        }
    }
}
