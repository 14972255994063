/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/util';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

@import '../../style/abstract/variables/font_awesome';
@import '../../style/abstract/variables/icon_font';
@import '../../style/abstract/button_extend';
@import '../../style/abstract/icon_font';
@import '../../style/abstract/typography';
@import '../../style/abstract/input';
@import '../../style/abstract/block';
@import '../../style/abstract/volume_pricing_guide';

.KlevuSearchResults {
    &.SearchOverlay-Results {
        padding: 0;
        overflow-y: scroll;

        @include desktop {
            max-height: 600px;
        }

        @include mobile {
            border-top: 1px solid var(--primary-divider-color);
        }

        > ul {
            margin-block-end: 10px;
        }
    }

    .SearchOverlay {
        &-SectionHeading {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 10px 3px;

            &:not(:first-child) {
                border-top: 1px solid var(--primary-divider-color);
            }

            > p {
                font-weight: 500;
                margin-block-end: 0;
            }

            @include mobile {
                padding-inline-start: 10px;
                padding-inline-end: 10px;
            }
        }

        &-Results {
            padding-block-start: 10px;
            padding-block-end: 10px;
            overflow-y: auto;
            max-height: 500px;

            > ul {
                overflow-y: visible;
            }

            @include mobile {
                display: block;
            }
        }

        &-NoResultsMessageWrapper {
            padding: 0 10px;
            display: grid;
            grid-template-columns: repeat(24, 1fr);

            .ExclamationMarkIcon {
                grid-column: span 1;

                @include mobile {
                    grid-column: span 3;
                }
            }
        }

        &-NoResultsMessage {
            grid-column: span 23;

            @include mobile {
                grid-column: span 21;
            }
        }

        &-ViewAllLink {
            font-size: 14px;
            color: var(--secondary-base-color);

            @include mobile {
                font-size: var(--paragraph-font-size-mobile);
            }

            &:hover {
                color: var(--link-hover);
            }
        }
    }

    .SearchItem {
        padding: 0;
        margin: 0;

        @include mobile {
            padding: 0;
        }

        &_isSelected {
            background-color: var(--search-item-hover-color);
        }

        &-Link {
            width: 100%;
            text-align: start;
            padding: 3px 12px;

            @include mobile {
                padding: 7px 12px;
            }
        }
    }

    button {
        cursor: pointer;
    }
}
