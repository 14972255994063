.BestSellersSection {
    .block-products-list.grid {
        overflow-x: unset;
        -ms-overflow-x: unset;
    }

    .widget-product-grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;
        max-width: 100%;

        .product-item {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-direction: column;
            height: 100%;
            gap: 20px;
            padding: 20px;
            background-color: var(--color-gray);
            @include border-radius(8px);

            &::before {
                display: none;
            }

            button {
                @include button;
                @include button-blue;
            }

            &-info {
                width: 100%;
            }

            &-details {
                margin-block-start: 20px;
            }

            &-photo {
                display: block;
            }

            &-name {
                h1, h2, h3, h4, h5, h6 {
                    margin-block: 10px 0;
                    line-height: 20px;
                    font-size: 17px;
                    font-weight: 400;
                }
            }

            .product-image-wrapper {
                display: block;
                width: 100%;
                padding: 20px;
                aspect-ratio: 1 / 1;
                background-color: var(--color-white);

                .product-image-photo {
                    display: block;
                    margin-inline: auto;
                    object-fit: contain;
                    width: 100% !important;
                    height: unset !important;
                    aspect-ratio: 1 / 1;
                }
            }

            .price-box {
                span {
                    font-size: 24px;
                    line-height: 28px;
                    font-weight: 500;
                    color: var(--color-dark-blue);
                }

                .price-label {
                    display: inline-block;
                    margin-inline-end: 5px;
                }
            }
        }
    }
}

@media (max-width: 1000px) {
    .BestSellersSection {
        .widget-product-grid {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}

@media (max-width: 800px) {
    .BestSellersSection {
        .widget-product-grid {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

@media (max-width: 500px) {
    .BestSellersSection {
        .widget-product-grid {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}
