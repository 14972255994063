@mixin icon-font(
    $icon-font-content,
    $icon-font-color,
    $icon-font-size: $font-standard-size,
    $icon-font-weight: '',
    $icon-font-position: before,
    $icon-font-family: var(--icon-font-family)
) {
    @if $icon-font-position == after {
        &::after {
            font-family: $icon-font-family;
            font-size: $icon-font-size;
            content: $icon-font-content;
            color: $icon-font-color;

            @if $icon-font-weight != '' {
                font-weight: $icon-font-weight;
            }
        }
    }
}
