/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/util';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

@import '../../style/abstract/variables/font_awesome';
@import '../../style/abstract/variables/icon_font';
@import '../../style/abstract/button_extend';
@import '../../style/abstract/icon_font';
@import '../../style/abstract/typography';
@import '../../style/abstract/input';
@import '../../style/abstract/block';
@import '../../style/abstract/volume_pricing_guide';

:root {
    --loader-scale: 1;
}

.Loader {
    background: rgba(255, 255, 255, .6) !important;
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 0;
    inset-inline-end: 0;
    inset-block-end: 0;
    z-index: 75;
    margin: auto;
    opacity: 0;
    animation: appear 1ms 200ms forwards;

    &-Main {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background: radial-gradient(farthest-side, var(--color-dark-blue) 94%, #0000) top/8px 8px no-repeat, conic-gradient(#0000 30%, var(--color-dark-blue));
        -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
        animation: spinner-d3wgkg 0.8s infinite linear;
    }

    &-Scale {
        position: absolute;
        transform: translate(-50%, -50%) scale(var(--loader-scale));
        inset-inline-start: 50%;
        inset-block-start: calc(50% - (3px * var(--loader-scale)));

        &:dir(rtl) {
            transform: translate(50%, -50%) scale(var(--loader-scale));
        }
    }
}

@keyframes spinner-d3wgkg {
    to {
        transform: rotate(1turn);
    }
}