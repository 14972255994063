:root {
    --default-button-radius: 3px;
}

button {
    color: var(--primary-text-color);
    @include lib-transition;

    & * {
        color: var(--primary-text-color);
    }
}

.Button {
    > svg {
        margin-inline-end: 14px;
    }
}
