/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/util';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

@import '../../style/abstract/variables/font_awesome';
@import '../../style/abstract/variables/icon_font';
@import '../../style/abstract/button_extend';
@import '../../style/abstract/icon_font';
@import '../../style/abstract/typography';
@import '../../style/abstract/input';
@import '../../style/abstract/block';
@import '../../style/abstract/volume_pricing_guide';

.ResetAttributes {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    &-AttributeValueWrapper {
        height: 30px;
        margin: 0 10px 10px 0;

        &:hover {
            .ResetAttributes {
                &-AttributeValue {
                    border-color: var(--link-hover);
                }

                &-AttributeOption {
                    color: var(--link-hover);
                }

            }

            .CloseIcon {
                fill: var(--link-hover);
            }
        }
    }

    &-AttributeValue {
        width: fit-content;
        height: 30px;
        margin: 0;
        flex-direction: row-reverse;
        justify-content: flex-start;
        border: 1px solid var(--link-color);
        background-color: var(--color-white);
        cursor: pointer;

        @include border-radius(15px);

        > * {
            display: block;
        }
    }

    &-AttributeText {
        margin-inline-end: 15px;
    }

    &-AttributeOption {
        font-size: 14px !important;
        line-height: 14px !important;
        font-weight: 500 !important;
    }

    &-AttributeLabel {
        display: none;
    }

    .CloseIcon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 18px !important;
        height: 18px !important;
        margin-inline-end: 10px;
        padding: 0;
    }
}

@include desktop {
    .ResetAttributes {
        &-AttributeValueWrapper {
            &:first-child {
                margin-block-start: 15px;
            }
        }
    }
}