@mixin volume-pricing-guide-base-styles {
    width: 100%;
    padding: 20px;
    background-color: var(--color-gray);

    @include border-radius(4px);

    &-Header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-block-end: 20px;
    }

    &-Title {
        font-weight: 500;
    }

    &-Table {
        width: 100%;

        thead th, tbody td {
            text-align: center;
        }
    }

    &-Qty {
        width: 50px;
    }

    &-PricePerItem {
        width: auto;

        &_hasSpecialPrice {
            .VolumePricingGuide {
                &-Price {
                    text-decoration: line-through;
                }

                &-SpecialPrice {
                    color: var(--sale-color);
                    margin-inline-start: 8px;
                }
            }
        }
    }

    &-PricePerCase {
        width: 80px;
    }
}

@mixin volume-pricing-guide-desktop-styles {
    &-Title {
        font-size: 19px;
        line-height: 23px;
    }

    .ProductUnitsPerCase {
        font-size: 15px;
        line-height: 20px;
    }
}

@mixin volume-pricing-guide-mobile-styles {
    padding: 0;

    &-Header {
        margin-block-end: 0;
        padding: 15px 15px 0;

        > span {
            display: block;
        }
    }

    &-Title {
        font-size: 18px;
        line-height: 26px;
    }

    &-TableWrapper {
        @include border-radius(4px);

        padding: 15px;
    }

    &-Table {
        thead {
            tr:last-child {
                border-bottom: var(--table-body-border);
            }

            th {
                padding-block-start: 0;
            }
        }

        tbody {
            tr:last-child {
                td {
                    padding-block-end: 0;
                }
            }
        }
    }

    &-Row {
        &:last-child {
            border-bottom: none;
        }
    }

    .ProductUnitsPerCase {
        font-size: 15px;
        line-height: 24px;
    }
}

@mixin volume-pricing-guide-remove-header {
    &-Header {
        display: none;
    }

    &-TableWrapper {
        thead {
            tr {
                th {
                    padding-block-start: 0;
                }
            }
        }

        tbody {
            tr {
                &:last-child {
                    border-bottom: none;

                    td {
                        padding-block-end: 0;
                    }
                }
            }
        }
    }
}

@mixin volume-pricing-guide-white-background {
    &-Header {
        padding-inline: 0;
        padding-block-end: 10px;
    }

    &-TableWrapper {
        background-color: var(--color-white);
        border: var(--table-body-border);
    }
}