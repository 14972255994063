.TiledSection {
    width: 100%;

    &-Tile {
        position: relative;

        > a {
            line-height: 0;
            display: block;

            > img {
                @include border-radius(8px);
            }
        }
    }

    &-TileContentWrapper {
        height: calc(100% - 52px);
        inset-block-start: 26px;
        position: absolute;
        z-index: 1;
    }

    &-TileHasPrice {
        .TiledSection {
            &-TileContentWrapper {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-start;
            }
        }
    }

    &-TileTextLight {
        color: var(--color-white);

        *, *:hover, *:active, *:focus {
            color: var(--color-white);
        }
    }

    &-TileTextDark {
        color: var(--color-very-dark-gray);

        *, *:hover, *:active, *:focus {
            color: var(--color-very-dark-gray);
        }
    }

    &-TileTitle {
        margin-block: 0 1rem;

        span {
            display: block;
            font-weight: 500;
        }
    }

    &-TileSubtitle {
        margin-block-end: 4px;
    }

    &-TileLink {
        a {
            font-size: 16px;
            line-height: 19px;
            text-decoration: underline;
        }
    }

    &-TileButton {
        margin-block-start: 1rem;
    }

    &-TilePriceWrapper {
        margin-block: 0;
    }

    &-TilePriceLabel {
        display: block;
        font-size: 17px;
        line-height: 20px;
    }

    &-TilePriceCurrency {
        font-size: 25px;
        line-height: 29px;
        font-weight: 500;
    }

    &-TilePriceAmount {
        font-size: 41px;
        line-height: 41px;
        font-weight: 500;
    }

    &-TileTopLeft {
        grid-area: topleft;

        > a {
            aspect-ratio: 330 / 227;
        }
    }

    &-TileTopMiddle {
        grid-area: topmiddle;

        > a {
            aspect-ratio: 680 / 393;
        }
    }

    &-TileTopRight {
        grid-area: topright;

        > a {
            aspect-ratio: 66 / 101;
        }
    }

    &-TileBottomLeft {
        grid-area: bottomleft;

        > a {
            aspect-ratio: 66 / 101;
        }
    }

    &-TileBottomMiddle {
        grid-area: bottommiddle;

        > a {
            aspect-ratio: 680 / 339;
        }
    }

    &-TileBottomRight {
        grid-area: bottomright;

        > a {
            aspect-ratio: 330 / 227;
        }
    }

    &-TileTopLeft, &-TileBottomLeft, &-TileTopRight, &-TileBottomRight {
        .TiledSection-TileContentWrapper {
            inset-inline-start: 18px;
        }

        .TiledSection {
            &-TileTitle, &-TileTitle * {
                font-size: 30px;
                line-height: 36px;
            }

            &-TileTitle * {
                margin-block: 0;
            }
        }
    }

    &-TileTopMiddle, &-TileBottomMiddle {
        .TiledSection {
            &-TileContentWrapper {
                inset-inline-start: 33px;
            }

            &-TileTitle, &-TileTitle * {
                font-size: 51px;
                line-height: 55px;
            }
        }
    }
}

@include desktop {
    .TiledSection {
        margin-inline: auto;
        padding: 0;
        max-width: var(--content-wrapper-width);

        &-TileWrapper {
            display: grid;
            grid-gap: 20px;
            grid-template-areas:
                "topleft topmiddle topmiddle topright"
                "topleft topmiddle topmiddle topright"
                "bottomleft topmiddle topmiddle topright"
                "bottomleft bottommiddle bottommiddle topright"
                "bottomleft bottommiddle bottommiddle bottomright"
                "bottomleft bottommiddle bottommiddle bottomright";
        }
    }
}

@include mobile {
    .TiledSection {
        &-TileWrapper {
            display: grid;
            grid-gap: 20px;
            grid-template-areas:
                "topmiddle topmiddle"
                "bottomleft topright"
                "topleft bottomright"
                "bottommiddle bottommiddle";
        }

        &-TileTitle {
            text-align: start;
        }
    }
}

@media screen and (max-width: 580px) {
    .TiledSection {
        &-TileWrapper {
            display: flex;
            flex-direction: column;
            gap: 14px;
        }

        &-TileContentWrapper {
            height: calc(100% - 36px);
            inset-block-start: 18px;
        }

        &-TilePriceAmount {
            font-size: 30px;
            line-height: 36px;
        }

        &-TileTopMiddle, &-TileBottomMiddle {
            .TiledSection {
                &-TileContentWrapper {
                    inset-block-start: 18px;
                    inset-inline-start: 18px;
                }

                &-TileTitle, &-TileTitle * {
                    font-size: 30px;
                    line-height: 36px;
                }
            }
        }
    }
}

@media screen and (min-width: 581px) {
    .TiledSection {
        &-TileButton {
            padding: 26px !important;
            font-size: 18px !important;
            line-height: 20px !important;

            &:hover, &:focus, &:active {
                padding: 26px !important;
            }
        }
    }
}

@media screen and (min-width: 500px) and (max-width: 580px) {
    .TiledSection {
        &-TileButton {
            padding: 8px 12px;
            font-size: 14px;
            line-height: 14px;

            &:hover, &:focus, &:active {
                padding: 8px 12px;
            }
        }
    }
}

@media screen and (min-width: 500px) {
    .TiledSection {
        &-TileButton {
            @include button;
            @include button-hollow-dark-gray;
        }
    }
}

@media screen and (max-width: 499px) {
    .TiledSection {
        &-TileButton {
            font-size: 16px;
            line-height: 19px;
            text-decoration: underline;
        }
    }
}
