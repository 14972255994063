/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/util';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

@import '../../style/abstract/variables/font_awesome';
@import '../../style/abstract/variables/icon_font';
@import '../../style/abstract/button_extend';
@import '../../style/abstract/icon_font';
@import '../../style/abstract/typography';
@import '../../style/abstract/input';
@import '../../style/abstract/block';
@import '../../style/abstract/volume_pricing_guide';

$custom-assortment-menu-additional-height: 164px;

.CustomAssortmentMenu {
    &-Header {
        height: auto;
        padding-block-end: 10px;
        border-bottom: none;
    }

    &-ContentWrapper {
        padding-block-start: 0;
        height: calc(100vh - 94px - #{$custom-assortment-menu-additional-height} - 20px);
        overflow-y: auto;
    }

    &-RemainingQty {
        margin-block-end: 20px;
        padding: 10px;
        background-color: var(--color-dark-blue);
        text-align: center;

        @include border-radius(4px);

        * {
            color: var(--color-white);
        }
    }

    &-IncrementQty {
        text-align: end;

        span {
            font-size: 17px;
        }
    }

    &-TotalQty {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-block: 15px;

        * {
            font-weight: 500;
        }
    }

    &-TotalQtySelected:not(.Complete) {
        color: var(--color-light-gray);
    }

    /** this should be the same as .CartOverlay-Additional  */
    &-Additional {
        display: block;
        position: absolute;
        inset-block-end: 0;
        width: 100%;
        height: $custom-assortment-menu-additional-height;
        padding: 30px;
        z-index: 10;
        @include box-shadow(0px -1px 6px rgba(0, 0, 0, 0.25));
    }

    /** this should be the same as .MiniCart-SubtotalWrapper  */
    &-SubtotalWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-block-end: 30px;

        > span {
            font-weight: 500;
        }
    }

    /** this should be the same as .MiniCart-Actions  */
    &-Actions {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-gap: 10px;

        .AddToCart {
            width: 100%;
            margin: 0;
            grid-column: span 7;
        }
    }

    &-CancelButton {
        margin: 0;
        grid-column: span 5;
        @include button-hollow-orange;

        + .AddToCart, + .CustomAssortmentMenu-AddToCartWrapper {
            margin: 0;
            grid-column: span 7;
        }
    }

    &-AddToCartWrapper {
        .Field-ErrorMessages {
            text-align: center;
        }
    }
}

@include desktop {
    .CustomAssortmentMenu {
        &-Wrapper {
            max-width: 600px;
        }

        &-ContentWrapper {
            * {
                font-size: 20px;
            }
        }
    }
}

@include mobile {
    .CustomAssortmentMenu {
        &-ContentWrapper {
            * {
                font-size: 17px;
            }
        }
    }
}
