.CustomerTypesSection {
    &-Tile {
        position: relative;
    }

    &-TileTitle {
        display: block;
        padding: 1rem 2rem;
        position: absolute;
        inset-inline-start: 0;
        inset-block-end: 30px;
        z-index: 1;
        background-color: var(--color-white);

        a {
            font-size: 30px;
            font-weight: 600;
            line-height: 36px;
            color: var(--text-color);
        }
    }

    &-TileWrapper {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
    }

    &-TileLink {
        display: block;
        line-height: 0;
        aspect-ratio: 447 / 289;
    }

    &-TileImage {
        @include border-radius(10px);
    }
}

@include mobile {
    .CustomerTypesSection {
        &-TileTitle a {
            font-size: 30px !important;
        }

        &-TileWrapper {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}
