/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/util';
@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

@import '../../style/abstract/variables/font_awesome';
@import '../../style/abstract/variables/icon_font';
@import '../../style/abstract/button_extend';
@import '../../style/abstract/icon_font';
@import '../../style/abstract/typography';
@import '../../style/abstract/input';
@import '../../style/abstract/block';
@import '../../style/abstract/volume_pricing_guide';

.Pagination {
    .PaginationLink {
        width: 40px;
        height: 40px;
        margin-inline-end: 25px;
        display: flex;
        justify-content: center;
        align-items: center;

        &_isCurrent, &:hover {
            color: var(--primary-base-color);
            border-color: var(--primary-base-color);

            .ChevronIcon {
                fill: var(--primary-base-color);
            }
        }
    }
}

@include mobile {
    .Pagination {
        display: flex;
    }
}
