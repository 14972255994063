@mixin button {
    align-items: center;
    background-color: var(--button-background);
    border-radius: var(--button-border-radius);
    display: inline-flex;
    color: var(--button-color);
    font-size: 14px;
    font-weight: var(--button-font-weight);
    justify-content: center;
    height: var(--button-height);
    line-height: normal;
    text-transform: uppercase;
    padding-inline: var(--button-padding);
    text-decoration: var(--button-text-decoration);
    will-change: background-color, color, border;
    @include lib-transition;

    * {
        text-transform: uppercase;
        font-weight: var(--button-font-weight);
    }

    @include mobile {
        user-select: none;
        width: 100%;
    }

    border: {
        color: var(--button-border);
        style: solid;
        width: var(--button-border-width);
    }

    &:hover,
    &:focus {
        text-decoration: var(--button-hover-text-decoration);
        background-color: var(--button-hover-background);
        border-color: var(--button-hover-border);
        border-radius: var(--button-hover-border-radius);
        color: var(--button-hover-color);
        height: var(--button-hover-height);
        padding-inline: var(--button-hover-padding);
    }

    &:not(:disabled) {
        cursor: pointer;
    }

    &[disabled] {
        @include button-disabled;
    }

    &_isHollow {
        @include button-hollow;
    }

    &_isWithoutBorder {
        border: 0;
    }

    &_likeLink {
        --button-border: transparent;
        --button-background: transparent;
        --button-color: var(--primary-base-color);
        --button-hover-border: transparent;
        --button-hover-background: transparent;
        --button-hover-color: var(--primary-base-color);
        --button-padding: 0;
        --button-hover-padding: 0;
        --button-height: min-content;
        --button-hover-height: min-content;

        border: none;
        text-transform: none;
        margin: 0;
        padding: 0;
        font-weight: normal;
        text-align: start;

        &:hover,
        &:focus {
            text-decoration: underline;
            padding: 0;
        }
    }

    [class*="Icon"] {
        width: 20px;
        fill: var(--button-color);
    }

    &.Small {
        height: 39px;

        &:hover, &:focus {
            height: 39px;
        }
    }
}

@mixin button-blue {
    color: var(--color-white);
    background-color: var(--color-dark-blue);
    border: none;

    * {
        color: var(--color-white);
    }

    &:hover, &:active, &:focus {
        color: var(--color-white);
        background-color: var(--color-dark-blue);
        border: none;
    }
}

@mixin button-hollow {
    background-color: var(--hollow-button-background);
    border-color: var(--hollow-button-border);
    border-radius: var(--hollow-button-border-radius);
    color: var(--hollow-button-color);
    height: var(--hollow-button-height);
    padding-inline: var(--hollow-button-padding);
    text-decoration: var(--hollow-button-text-decoration);

    &:hover, &:focus {
        background-color: var(--hollow-button-hover-background);
        border-color: var(--hollow-button-hover-border);
        border-radius: var(--hollow-button-hover-border-radius);
        color: var(--hollow-button-hover-color);
        height: var(--hollow-button-hover-height);
        padding-inline: var(--hollow-button-hover-padding);
        text-decoration: var(--hollow-button-hover-text-decoration);
    }
}

@mixin button-hollow-white {
    color: var(--footer-button-hollow-color);
    border-color: var(--footer-button-hollow-border-color);

    [class*="Icon"] {
        fill: var(--footer-button-hollow-color);
    }

    &:hover, &:focus {
        color: var(--footer-button-hollow-color);
        border-color: var(--footer-button-hollow-border-color);

        [class*="Icon"] {
            fill: var(--footer-button-hollow-color);
        }
    }
}

@mixin button-hollow-orange {
    color: var(--button-background);
    border-color: var(--button-background);

    [class*="Icon"] {
        fill: var(--button-background);
    }

    &:hover, &:focus {
        color: var(--button-background);
        border-color: var(--button-background);

        [class*="Icon"] {
            fill: var(--button-background);
        }
    }
}

@mixin button-hollow-medium-gray($bgColor: var(--color-white), $bgColorHover: var(--color-white)) {
    color: var(--color-medium-gray);
    border-color: var(--color-medium-gray);
    background-color: $bgColor;

    [class*="Icon"] {
        fill: var(--color-medium-gray);
    }

    &:hover, &:focus {
        color: var(--color-medium-gray);
        border-color: var(--color-medium-gray);
        background-color: $bgColorHover;

        [class*="Icon"] {
            fill: var(--color-medium-gray);
        }
    }
}

@mixin button-solid-medium-gray {
    color: var(--color-white);
    background-color: var(--color-medium-gray);
    border-color: var(--color-medium-gray);

    &:hover, &:focus {
        background-color: var(--color-dark-gray);
        border-color: var(--color-dark-gray);
    }
}

@mixin button-hollow-dark-gray($bgColor: var(--color-white), $bgColorHover: var(--color-white)) {
    color: var(--color-very-dark-gray);
    border-color: var(--color-very-dark-gray);
    background-color: $bgColor;

    [class*="Icon"] {
        fill: var(--color-very-dark-gray);
    }

    &:hover, &:focus {
        color: var(--color-very-dark-gray);
        border-color: var(--color-very-dark-gray);
        background-color: $bgColorHover;

        [class*="Icon"] {
            fill: var(--color-very-dark-gray);
        }
    }
}

@mixin button-disabled {
    border-color: var(--button-disabled-border-color);
    color: var(--button-disabled-color);
    background-color: var(--button-disabled-background-color);
    opacity: var(--button-disabled-opacity);

    [class*="Icon"] {
        fill: var(--button-disabled-color);
    }

    span {
        color: var(--button-disabled-color);

        [class*="Icon"] {
            fill: var(--button-disabled-color);
        }
    }
}
